import React, { useContext, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { InvoicesAppContext } from '../../context';
import { mobStyles, deskStyles } from './PackagesModalStyles';
import { getInvoicesPaymentPath } from '../../routes';
import { PaymentOption } from '../../constants';
import { openNewPaymentWindow, paymentData } from '../../helpers';
import { numberWithCurrency } from '../../../../helpers';
import { PaymentButton } from '../PaymentButton';
import { ChoseCardModal } from './ChoseCardModal';
import { usePaymentModal } from './usePaymentModal';

export const PaymentModal = () => {
  const { t } = useTranslation();
  const {
    bankCardProvider,
    selectedInvoice,
    isModalVisible,
    setIsModalVisible,
    balanceValueData,
    setHasErrors,
    isMobileSite
  } = useContext(InvoicesAppContext);

  const [selectedOption, setSelectedOption] = useState(bankCardProvider);
  const [blockBtnOnRequest, setBlockBtnOnRequest] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);

  const { portmanat, wallet } = PaymentOption;
  const { headers, body } = paymentData(selectedOption);

  Modal.setAppElement(document.querySelector('[data-behavior="react"]'));

  const {
    userCards,
    selectedCard,
    openChoseCardModal,
    choseCardModalVisible,
    closeChoseCardModal,
    setSelectedCard
  } = usePaymentModal();

  const [isUserCardSelected, setIsUserCardSelected] = useState(userCards.length > 0);

  const onClose = () => {
    setIsModalVisible(false);
  };

  const initializePayment = async () => {
    const path = getInvoicesPaymentPath(selectedInvoice.id);

    try {
      const res = await fetch(path, {
        method: 'POST',
        headers,
        body: isUserCardSelected
          ? JSON.stringify({
            payment_method: selectedOption,
            service_id: 'pay_invoice',
            medium: 'desktop',
            options: {
              card_token: selectedCard.token
            }
          })
          : body
      });

      const data = await res.json();

      if (isUserCardSelected) {
        data.url = `/wallet/finish?id=${data?.result.payment_id}`;
      }

      if (data.url) {
        setDataUrl(data);
      } else {
        openNewPaymentWindow(data);
      }

      return data;
    } catch (e) {
      setHasErrors(e);
      onClose();
    }
  };

  const isBalanceEnough = Number(balanceValueData) >= Number(selectedInvoice.amount);

  const blockPaymentBtn = selectedOption === wallet ? !isBalanceEnough : null;

  const handlePaymentClick = useCallback(
    (res, paymentUrl, selectedPayment, openedWin) => {
      if (res?.status === 'unpaid') {
        if (selectedPayment === 'wallet') {
          setBlockBtnOnRequest(true);
          initializePayment(selectedPayment);
        } else if (selectedPayment === 'terminal') {
          openKioskModal();
        } else {
          openNewPaymentWindow(paymentUrl, openedWin);
        }
      }
    },
    [selectedInvoice?.id, selectedOption, dataUrl]
  );

  const modalStyles = isMobileSite ? mobStyles : deskStyles;

  const description = () => (selectedInvoice?.services?.discount ? 'descriptionDiscount' : 'description');

  const digitalPayType = window.Detector.ios ? 'apple' : 'google';

  const handleChangeOption = isUserCard => e => {
    const { value } = e.target;

    console.log('handleChangeOption', value);

    setSelectedOption(value);
    setIsUserCardSelected(isUserCard);
  };

  const handleSelectWallet = () => {
    handleChangeOption(false)({ target: { value: wallet } });
  };

  return (
    <Modal isOpen={isModalVisible} onRequestClose={onClose} style={modalStyles}>
      <header className="package-payment_modal_header">
        <p className="package-payment_modal_title">
          {t('payment.modal.title', { name: selectedInvoice?.services?.tariff_plan_title })}
        </p>
        <button type="button" onClick={onClose} className="package-payment_modal_close-btn" />
      </header>
      <div className="package-payment_modal_description">
        <p>
          {t(`payment.modal.${description()}`, {
            count: selectedInvoice?.services?.paid_ads_count,
            sum: selectedInvoice?.services?.wallet_amount,
            discount: selectedInvoice?.services?.discount
          })}
        </p>
      </div>
      <div className="package-payment_modal_source">
        <p>{t('payment.source')}</p>
      </div>
      <section className="package-payment_modal_body">
        <ul>
          {!!userCards.length && selectedCard && bankCardProvider === 'yigim' && (
            <li className="package-payment_modal_option payment-method">
              <input
                id={selectedCard.id}
                name="payment-option"
                type="radio"
                value={bankCardProvider}
                checked={selectedOption === bankCardProvider && isUserCardSelected}
                onChange={handleChangeOption(true)}
              />
              <label
                className={`option option__${selectedCard.system.toLowerCase()}`}
                htmlFor={selectedCard.id}
              >
                {selectedCard.name}
              </label>
              {userCards.length > 1 && (
                <button className="my-cards-btn" type="button" onClick={openChoseCardModal}>
                  {t('my-cards')}
                </button>
              )}
            </li>
          )}
          <li className="package-payment_modal_option">
            <input
              id={bankCardProvider}
              name="payment-option"
              type="radio"
              value={bankCardProvider}
              checked={selectedOption === bankCardProvider && !isUserCardSelected}
              onChange={handleChangeOption(false)}
            />
            <label htmlFor={bankCardProvider}>
              {t('modal.card')}
              {isMobileSite && bankCardProvider === 'yigim' && (
                <span
                  className={`digital-payment-provider digital-payment-provider--${digitalPayType}-pay`}
                />
              )}
            </label>
          </li>
          <li className="package-payment_modal_option">
            <input
              id={portmanat}
              name="payment-option"
              type="radio"
              value={portmanat}
              checked={selectedOption === portmanat}
              onChange={handleChangeOption(false)}
            />
            <label htmlFor={portmanat}>{t('modal.portmanat')}</label>
          </li>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li className="package-payment_modal_option" onClick={handleSelectWallet}>
            <input
              id={wallet}
              name="payment-option"
              type="radio"
              value={wallet}
              checked={selectedOption === wallet}
              onChange={handleChangeOption(false)}
            />
            <label htmlFor={wallet}>
              {t('modal.wallet', { sum: numberWithCurrency(balanceValueData) })}
            </label>
          </li>
        </ul>
      </section>
      <footer className="package-payment_modal_footer">
        <PaymentButton
          disabled={blockBtnOnRequest || blockPaymentBtn}
          checkUnpaidStatus={handlePaymentClick}
          buttonClassName="invoices_payment-modal-button"
          invoiceId={selectedInvoice?.id}
          initializePayment={initializePayment}
          selectedPayment={selectedOption}
          isUserCardSelected={isUserCardSelected}
        />
        <p className="package-payment_modal_confirm-rules">
          <span>{t('modal.confirmRules1')}</span>
          <a href="/pages/terms-and-conditions">{t('modal.confirmRules2')}</a>
          <span>{t('modal.confirmRules4')}</span>
          <a href="/pages/proposal">{t('modal.confirmRules5')}</a>
          <span>{t('modal.confirmRules6')}</span>
        </p>
      </footer>
      {choseCardModalVisible && (
        <>
          <div className="chose-card-modal__overlay" onClick={closeChoseCardModal} />
          <ChoseCardModal
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            closeChoseCardModal={closeChoseCardModal}
            userCards={userCards}
            isMobileSite={isMobileSite}
          />
        </>
      )}
    </Modal>
  );
};
