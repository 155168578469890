import styled, { css } from 'styled-components';

export const MobileMenuContainer = styled.div<{ isClose: boolean }>`
  border-radius: 7px 7px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;

  ${(props: { isClose: boolean }) =>
    props.isClose
      ? css`
          animation: toDown 0.3s ease-in-out;
        `
      : css`
          animation: toUp 0.3s ease-in-out;
        `}

  @keyframes toUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes toDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #212c3a;
  font-weight: 500;
`;
