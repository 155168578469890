import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesktopControlsContext } from '../../../../context/DesktopControlsContext';
import { Card } from '../../../../globalTypes';
import closeImg from '../../../../images/close.svg';
import EditCard from '../../../Shared/EditCard';
import { CloseButton, RenameDialogContainer, RenameDialogHeader, Title } from './styled';

interface RenameDialogProps {
  isClose: boolean;
  close: () => void;
}

const RenameDialog: FC<RenameDialogProps> = ({ isClose, close }) => {
  const { card, closeAll } = useDesktopControlsContext();
  const { t } = useTranslation();
  return (
    <RenameDialogContainer isClose={isClose}>
      <RenameDialogHeader>
        <Title>{t('rename_card')}</Title>
        <CloseButton onClick={close}>
          <img src={closeImg} alt="Close" />
        </CloseButton>
      </RenameDialogHeader>
      <EditCard isDesktop card={card as Card} close={closeAll} />
    </RenameDialogContainer>
  );
};

export default RenameDialog;
