import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 15px 25px 15px;

  li:last-child button {
    border-bottom: none;
    color: #ff586d;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #eaebf2;
  cursor: pointer;
  gap: 15px;
  -webkit-tap-highlight-color: transparent;

  font-size: 15px;
  line-height: 18px;
  color: #212c3a;
  font-weight: 400;
`;
