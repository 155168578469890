export const deskStyles = {
  position: 'relative',
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    boxSizing: 'border-box',
    verticalAlign: 'top',
    width: '455px',
    border: 'none'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000
  }
};

export const mobStyles = {
  position: 'relative',
  content: {
    position: 'absolute',
    background: '#f6f7fa',
    inset: 0,
    border: 0,
    borderRadius: 0,
    overflow: 'auto',
    outline: 'none',
    padding: '0px',
    width: '100%',
    height: '100vh'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1000
  }
};
