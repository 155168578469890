import styled, { css } from 'styled-components';
import { Container } from './styled';

export const SpinnerContainer = styled(Container)<{ isDesktop?: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px 15px;
  box-sizing: border-box;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      height: 279px;
    `}
`;

export const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #f6f7fa #f6f7fa transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-top: 50px;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent #ca1016 #ca1016;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;
