import React, { FC } from 'react';
import { useMobileControlsContext } from '../../../../context/MobileControlsContext';
import close from '../../../../images/close.svg';
import { MobileMenuContainer, ModalHeader, ModalTitle } from './styled';

type MobileMenuProps = {
  title: string;
  isCloseAnimationRunning: boolean;
  children: React.ReactNode;
};

const MobileMenu: FC<MobileMenuProps> = ({ title, children, isCloseAnimationRunning }) => {
  const { closeAll } = useMobileControlsContext();

  return (
    <MobileMenuContainer isClose={isCloseAnimationRunning}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <button onClick={closeAll}>
          <img src={close} alt="Close" />
        </button>
      </ModalHeader>
      {children}
    </MobileMenuContainer>
  );
};

export default MobileMenu;
