import React from 'react';
import { Card } from '../globalTypes';

type MobileControlsContextProps = {
  isMenuOpen: boolean;
  isRenameMenuOpen: boolean;
  isDialogOpen: boolean;
  isOverlayOpen: boolean;
  isOverlayCloseAnimationRunning: boolean;
  isMenuCloseAnimationRunning: boolean;
  isRenameMenuCloseAnimationRunning: boolean;
  isDialogCloseAnimationRunning: boolean;
  card: Card | null;
  openMenu: (card: Card) => void;
  openRenameMenu: () => void;
  openDialog: () => void;
  closeMenu: () => void;
  closeRenameMenu: () => void;
  closeDialog: () => void;
  closeAll: () => void;
};

const MobileControlsContext = React.createContext<MobileControlsContextProps>({
  isMenuOpen: false,
  isRenameMenuOpen: false,
  isDialogOpen: false,
  isOverlayOpen: false,
  isOverlayCloseAnimationRunning: false,
  isMenuCloseAnimationRunning: false,
  isRenameMenuCloseAnimationRunning: false,
  isDialogCloseAnimationRunning: false,
  card: null,
  openMenu: () => {},
  openRenameMenu: () => {},
  openDialog: () => {},
  closeMenu: () => {},
  closeRenameMenu: () => {},
  closeDialog: () => {},
  closeAll: () => {}
});

export const useMobileControlsContext = () => React.useContext(MobileControlsContext);

export const MobileControlsProvider: React.FC = ({ children }) => {
  const ANIMATION_DURATION = 250;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isRenameMenuOpen, setIsRenameMenuOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState<Card | null>(null);

  const [isOverlayCloseAnimationRunning, setIsOverlayCloseAnimationRunning] = React.useState(false);
  const [isMenuCloseAnimationRunning, setIsMenuCloseAnimationRunning] = React.useState(false);
  const [isRenameMenuCloseAnimationRunning, setIsRenameMenuCloseAnimationRunning] =
    React.useState(false);
  const [isDialogCloseAnimationRunning, setIsDialogCloseAnimationRunning] = React.useState(false);

  const openMenu = (card: Card) => {
    setSelectedCard(card);
    setIsMenuOpen(true);
    setIsOverlayOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const openRenameMenu = () => {
    closeMenu();
    setTimeout(() => {
      setIsRenameMenuOpen(true);
    }, ANIMATION_DURATION);
    if (!isOverlayOpen) {
      setIsOverlayOpen(true);
    }
  };
  const openDialog = () => {
    closeMenu();
    setTimeout(() => {
      setIsDialogOpen(true);
    }, ANIMATION_DURATION);
    if (!isOverlayOpen) {
      setIsOverlayOpen(true);
    }
  };

  const closeMenu = () => {
    setIsMenuCloseAnimationRunning(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsMenuCloseAnimationRunning(false);
    }, ANIMATION_DURATION);
  };

  const closeRenameMenu = () => {
    setIsRenameMenuCloseAnimationRunning(true);
    setIsOverlayCloseAnimationRunning(true);
    setTimeout(() => {
      setIsRenameMenuOpen(false);
      setIsRenameMenuCloseAnimationRunning(false);
      setIsOverlayCloseAnimationRunning(false);
    }, ANIMATION_DURATION);
  };

  const closeDialog = () => {
    setIsDialogCloseAnimationRunning(true);
    setIsOverlayCloseAnimationRunning(true);
    setTimeout(() => {
      setIsDialogOpen(false);
      setIsDialogCloseAnimationRunning(false);
      setIsOverlayCloseAnimationRunning(false);
    }, ANIMATION_DURATION);
  };

  const closeAll = () => {
    if (isMenuOpen) {
      closeMenu();
    }
    if (isRenameMenuOpen) {
      closeRenameMenu();
    }
    if (isDialogOpen) {
      closeDialog();
    }
    document.body.style.overflow = 'unset';
    setIsOverlayCloseAnimationRunning(true);
    setTimeout(() => {
      setIsOverlayOpen(false);
      setIsOverlayCloseAnimationRunning(false);
    }, ANIMATION_DURATION);
  };

  return (
    <MobileControlsContext.Provider
      value={{
        isMenuOpen,
        isRenameMenuOpen,
        isDialogOpen,
        openMenu,
        openRenameMenu,
        openDialog,
        closeMenu,
        closeRenameMenu,
        closeDialog,
        card: selectedCard,
        closeAll,
        isOverlayOpen,
        isOverlayCloseAnimationRunning,
        isMenuCloseAnimationRunning,
        isRenameMenuCloseAnimationRunning,
        isDialogCloseAnimationRunning
      }}
    >
      {children}
    </MobileControlsContext.Provider>
  );
};
