import styled, { css } from 'styled-components';
import { CenterSecondaryText } from '../../Shared/styled';

export const CardListContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const AddNewCardContainer = styled.div`
  background-color: #fff;
  border-radius: 14px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23EAEBF2FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 15px 20px;
  width: 230px;
  height: 150px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 77px;
  }

  p {
    font-size: 15px;
    line-height: 18px;
    color: #212c3a;
  }

  &:hover {
    p {
      color: #4c88f9;
    }
  }
`;

export const CardItemContainer = styled.div`
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #eaebf2;
  padding: 15px 20px;
  width: 230px;
  height: 150px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 30px;
`;

export const CardItemTitle = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #212c3a;
  word-wrap: break-word;
  width: 160px;
`;

export const CardItemContextButton = styled.button`
  cursor: pointer;
`;

export const CardItemNumber = styled.div`
  font-size: 16px;
  line-height: 17px;
  color: #212c3a;
  font-weight: 500;
  letter-spacing: 2.5px;
`;

export const CardItemBankName = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #8d94ad;
  gap: 10px;
`;

export const CardItemFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

export const CardItemProviderLogo = styled.img`
  width: 35px;
  height: 20px;
`;

export const CardItemExp = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #8d94ad;
`;

export const CardItemLabel = styled.div<{ expired?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  color: #3db460;
  background-color: #f6f7fa;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 10px;
  border-radius: 14px 0 14px 0;

  ${({ expired }) =>
    expired &&
    css`
      color: #ff586d;
      background-color: #ffe6e5;
    `}
`;

export const InfoText = styled(CenterSecondaryText)`
  margin-top: 115px;
`;
