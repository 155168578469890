import React, { FC } from 'react';
import {
  CardItemBankName,
  CardItemContainer,
  CardItemContextButton,
  CardItemExp,
  CardItemFooter,
  CardItemHeader,
  CardItemLabel,
  CardItemNumber,
  CardItemProviderLogo,
  CardItemTitle
} from './styled';
import menu from '../../../images/menuDesktop.svg';
import { Card } from '../../../globalTypes';
import { useTranslation } from 'react-i18next';
import { useDesktopControlsContext } from '../../../context/DesktopControlsContext';

interface CardItemProps {
  card: Card;
}

const CardItem: FC<CardItemProps> = ({ card }) => {
  const { t } = useTranslation();

  const btnRef = React.useRef<HTMLButtonElement>(null);

  const last4: string = card.pan.slice(-4);

  const {openContextMenu} = useDesktopControlsContext();

  const handleOpenContextMenu = () => {
    if (btnRef.current) {
      openContextMenu(btnRef.current, card);
    }
  }

  return (
    <CardItemContainer>
      <CardItemHeader>
        <CardItemTitle>{card.name}</CardItemTitle>
        <CardItemContextButton ref={btnRef} onClick={handleOpenContextMenu}>
          <img src={menu} alt="Menu" />
        </CardItemContextButton>
      </CardItemHeader>
      <CardItemNumber>{`•••• ${last4}`}</CardItemNumber>
      <CardItemBankName>{card.issuer}</CardItemBankName>
      <CardItemFooter>
        <CardItemProviderLogo src={card.system_url} />
        <CardItemExp>{card.expiry}</CardItemExp>
      </CardItemFooter>
      {card.primary && <CardItemLabel>{t('main_card')}</CardItemLabel>}
      {card.expired && <CardItemLabel expired>{t('expired')}</CardItemLabel>}
    </CardItemContainer>
  );
};

export default CardItem;
