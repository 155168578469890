import { useCardContext } from '../../../context/CardContext';
import React from 'react';
import { Spinner, SpinnerContainer } from '../../Shared/Spinner';
import EmptyList from '../../Shared/EmptyList';
import CardItem from './CardItem';
import { CardListContainer, InfoText } from './styled';
import AddNewCard from './AddNewCard';
import { Trans, useTranslation } from 'react-i18next';

const CardList = () => {
  const { cards, isLoading } = useCardContext();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <SpinnerContainer isDesktop>
        <Spinner />
      </SpinnerContainer>
    );
  }

  if (!cards.length) {
    return <EmptyList isDesktop />;
  }

  return (
    <>
      <CardListContainer>
        {cards.map(card => (
          <CardItem key={card.id} card={card} />
        ))}

        {cards.length < 6 && <AddNewCard />}
      </CardListContainer>
      <InfoText>
        <Trans
          i18nKey="user_card_description"
          values={[t('user_agreement'), t('offer_agreement')]}
          components={[
            <a
              href="/pages/terms-and-conditions"
              key="user_agreement"
              target="_blank"
              rel="noopener noreferrer"
            />,
            <a
              href="/pages/proposal"
              key="offer-agreement"
              target="_blank"
              rel="noopener noreferrer"
            />
          ]}
        />
      </InfoText>
    </>
  );
};

export default CardList;
