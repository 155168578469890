import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import noCardImage from '../../../images/no-cards.svg';
import AddNewCard from '../../Shared/AddNewCard';
import { CenterText } from '../styled';
import { NoCardContainer, NoCardsImage } from './styled';

interface EmptyListProps {
  isDesktop?: boolean;
}

const EmptyList: FC<EmptyListProps> = ({ isDesktop }) => (
  <NoCardContainer>
    <NoCardsImage src={noCardImage} alt="No cards" />
    <CenterText>
      <Trans i18nKey={'empty_list_text'} />
    </CenterText>
    <AddNewCard isDesktop={isDesktop} />
  </NoCardContainer>
);

export default EmptyList;
