import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from '../../../../globalTypes';
import { MenuButton } from './styled';

interface MenuListProps {
  menu: MenuItemType;
}

const MenuItem: FC<MenuListProps> = ({ menu }) => {
  const { t } = useTranslation();
  return (
    <li>
      <MenuButton onClick={menu.action}>
        <img src={menu.icon} alt={menu.text} />

        {t(menu.text)}
      </MenuButton>
    </li>
  );
};

export default MenuItem;
