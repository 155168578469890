export type Card = {
  id: number;
  name: string;
  user_id: number;
  token: string;
  pan: string;
  expiry?: string;
  system: string;
  issuer: string;
  active: boolean;
  expired: boolean;
  primary: boolean;
  color: string;
  archived: boolean;
  system_url: string;
};

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error'
}

export type NotificationMessage = {
  key: string;
  values?: string[];
};

export type Notification = {
  id: string;
  message: NotificationMessage;
  type: NotificationType;
};

export enum BooleanString {
  TRUE = 'true',
  FALSE = 'false'
}

export enum Behavior {
  REACT = 'react'
}

export type DataSet = {
  isMobileSite: BooleanString;
  behavior: Behavior;
};
export interface AppProps {
  dataset: DataSet;
}

export type Position = {
  top: number;
  left: number;
};

export interface MenuItemType {
  id: number;
  text: string;
  icon: string;
  action: () => void;
}
