import styled, { css } from 'styled-components';

export const NotificationContainer = styled.div<{ isClose: boolean; isDesktop: boolean }>`
  min-width: 345px;
  position: fixed;

  ${({ isDesktop }) =>
    isDesktop
      ? css`
          top: 15px;
        `
      : css`
          bottom: 15px;
        `}

  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  background-color: #212c3a;
  border-radius: 7px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;

  ${({ isClose }) =>
    isClose
      ? css`
          animation: hideNotification 0.3s ease-in-out;
        `
      : css`
          animation: showNotification 0.3s ease-in-out;
        `}

  @keyframes hideNotification {
    0% {
      opacity: 1;
      transform: translateY(-50%, 0);
    }
    100% {
      opacity: 0;

      ${({ isDesktop }) =>
        isDesktop
          ? css`
              transform: translate(-50%, -100%);
            `
          : css`
              transform: translate(-50%, 100%);
            `}
    }
  }

  @keyframes showNotification {
    0% {
      opacity: 0;

      ${({ isDesktop }) =>
        isDesktop
          ? css`
              transform: translate(-50%, -100%);
            `
          : css`
              transform: translate(-50%, 100%);
            `}
    }
    100% {
      opacity: 1;
      transform: translateY(-50%, 0);
    }
  }
`;
