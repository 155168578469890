import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: #212c3a;
  font-family: 'Nelvetica Neue', sans-serif;
  font-weight: 400;
`;

export const CenterText = styled(Text)`
  text-align: center;
`;

export const CenterSecondaryText = styled(CenterText)`
  color: #b1b8c6;
  font-size: 12px;
  line-height: 14px;

  a {
    color: #b1b8c6;
  }
`;
