import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogButtons,
  DialogCancelButton,
  DialogContainer,
  DialogRemoveButton,
  DialogText
} from './styled';

interface RemoveDialogProps {
  text: string;
  action: () => void;
  isClose: boolean;
  close: () => void;
}

const RemoveDialog: FC<RemoveDialogProps> = ({ text, action, isClose, close }) => {
  const { t } = useTranslation();

  return (
    <DialogContainer isClose={isClose}>
      <DialogText>{text}</DialogText>
      <DialogButtons>
        <DialogCancelButton onClick={close}>{t('cancel')}</DialogCancelButton>
        <DialogRemoveButton onClick={action}>{t('delete')}</DialogRemoveButton>
      </DialogButtons>
    </DialogContainer>
  );
};

export default RemoveDialog;
