import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMobileControlsContext } from '../../../context/MobileControlsContext';
import type { Card } from '../../../globalTypes';
import menu from '../../../images/menu.svg';
import {
  CardActions,
  CardBlock,
  CardDetails,
  CardInfo,
  CardMain,
  CardNumber,
  CardSecondaryText,
  CardType,
  CardWarning
} from './styled';

interface CardItemProps {
  card: Card;
}

const CardItem: FC<CardItemProps> = ({ card }) => {
  const { t } = useTranslation();

  const { openMenu } = useMobileControlsContext();

  const expMonth = card.expiry?.slice(0, 2);
  const expYear = card.expiry?.slice(2, 4);

  return (
    <CardBlock onClick={() => openMenu(card)}>
      <CardType src={card.system_url} alt={card.system} />
      <CardInfo>
        <CardNumber>{card.name}</CardNumber>
        <CardDetails>
          <CardSecondaryText>{card.issuer}</CardSecondaryText>
          {card.primary && <CardMain>{t('main_card')}</CardMain>}
          {!card.active && <CardWarning>{t('inactive_card')}</CardWarning>}
          {!card.active && !card.expired && (
            <CardWarning>
              <Trans i18nKey="expired_card" values={[expMonth, expYear]} />
            </CardWarning>
          )}
        </CardDetails>
      </CardInfo>
      <div>
        <CardActions>
          <img src={menu} alt="Menu" />
        </CardActions>
      </div>
    </CardBlock>
  );
};

export default CardItem;
