import React from 'react';
import NotificationComponent from '../components/Shared/Notification';
import type { Notification } from '../globalTypes';

interface NotificationContextProps {
  addNotification: (notification: Notification) => void;
}

export const NotificationContext = React.createContext<NotificationContextProps>({
  addNotification: () => {}
});

export const useNotificationContext = () => React.useContext(NotificationContext);

interface NotificationProviderProps {
  isDesktop?: boolean;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
  isDesktop = false
}) => {
  const ANIMATION_DURATION = 250;
  const DISPLAY_DURATION = 5000;

  const [notification, setNotifications] = React.useState<Notification | null>(null);
  const [isCloseAnimationRunning, setIsCloseAnimationRunning] = React.useState(false);

  const addNotification = (notification: Notification) => {
    setNotifications(notification);

    setTimeout(() => {
      setIsCloseAnimationRunning(true);
      setTimeout(() => {
        setNotifications(null);
        setIsCloseAnimationRunning(false);
      }, ANIMATION_DURATION);
    }, DISPLAY_DURATION);
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      {notification && (
        <NotificationComponent
          notification={notification}
          isClose={isCloseAnimationRunning}
          isDesktop={isDesktop}
        />
      )}
    </NotificationContext.Provider>
  );
};
