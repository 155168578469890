import styled, { css } from 'styled-components';

export default styled.button<{ fixedWidth?: boolean; mb?: number }>`
  width: 100%;
  height: 50px;
  background-color: #4c88f9;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;

  ${props =>
    props.fixedWidth &&
    css`
      width: 230px;
    `}

  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  &:disabled {
    background-color: #b2b2b2;
  }
`;
