import React from 'react';
import { Card, Position } from '../globalTypes';

const CONTEXT_MENU_ANIMATION_DURATION = 50;
const CONTEXT_MENU_REOPEN_ANIMATION_DURATION = 100;
const CONTEXT_MENU_WIDTH = 170;
const CONTEXT_MENU_SAFE_MARGIN = 200;
const DEFAULT_ANIMATION_DURATION = 250;

export type DesktopControlsContextProps = {
  card: Card | null;
  isRenameDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  isContextMenuOpen: boolean;
  isOverlayOpen: boolean;
  isRenameDialogCloseAnimationRunning: boolean;
  isDeleteDialogCloseAnimationRunning: boolean;
  isContextMenuCloseAnimationRunning: boolean;
  isOverlayCloseAnimationRunning: boolean;
  contextMenuPosition: Position;
  openRenameDialog: () => void;
  openDeleteDialog: () => void;
  openContextMenu: (anchor: HTMLButtonElement, card: Card) => void;
  closeRenameDialog: () => void;
  closeDeleteDialog: () => void;
  closeAll: () => void;
};

const DesktopControlsContext = React.createContext<DesktopControlsContextProps>({
  card: null,
  isRenameDialogOpen: false,
  isDeleteDialogOpen: false,
  isContextMenuOpen: false,
  isOverlayOpen: false,
  isRenameDialogCloseAnimationRunning: false,
  isDeleteDialogCloseAnimationRunning: false,
  isContextMenuCloseAnimationRunning: false,
  isOverlayCloseAnimationRunning: false,
  contextMenuPosition: { top: 0, left: 0 },
  openRenameDialog: () => {},
  openDeleteDialog: () => {},
  openContextMenu: (anchor: HTMLButtonElement, card: Card) => {},
  closeRenameDialog: () => {},
  closeDeleteDialog: () => {},
  closeAll: () => {}
});

export const useDesktopControlsContext = () => React.useContext(DesktopControlsContext);

export const DesktopControlsProvider: React.FC = ({ children }) => {
  const [card, setCard] = React.useState<Card | null>(null);
  const [contextMenuCard, setContextMenuCard] = React.useState<Card | null>(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = React.useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [isRenameDialogCloseAnimationRunning, setIsRenameDialogCloseAnimationRunning] =
    React.useState(false);
  const [isDeleteDialogCloseAnimationRunning, setIsDeleteDialogCloseAnimationRunning] =
    React.useState(false);
  const [isContextMenuCloseAnimationRunning, setIsContextMenuCloseAnimationRunning] =
    React.useState(false);
  const [isOverlayCloseAnimationRunning, setIsOverlayCloseAnimationRunning] = React.useState(false);
  const [contextMenuPosition, setContextMenuPosition] = React.useState<Position>({
    top: 0,
    left: 0
  });

  const openRenameDialog = () => {
    setIsOverlayOpen(true);
    setIsRenameDialogOpen(true);
  };

  const openDeleteDialog = () => {
    setIsOverlayOpen(true);
    setIsDeleteDialogOpen(true);
  };

  const closeRenameDialog = () => {
    setIsRenameDialogCloseAnimationRunning(true);
    setTimeout(() => {
      setIsRenameDialogOpen(false);
      setIsRenameDialogCloseAnimationRunning(false);
      setIsOverlayOpen(true);
    }, DEFAULT_ANIMATION_DURATION);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogCloseAnimationRunning(true);
    setTimeout(() => {
      setIsDeleteDialogOpen(false);
      setIsDeleteDialogCloseAnimationRunning(false);
      setIsOverlayOpen(true);
    }, DEFAULT_ANIMATION_DURATION);
  };

  React.useEffect(() => {
    if (!isContextMenuOpen) return;

    window.addEventListener('click', closeContextMenu);
    window.addEventListener('scroll', closeContextMenu);

    return () => {
      window.removeEventListener('click', closeContextMenu);
      window.removeEventListener('scroll', closeContextMenu);
    };
  }, [isContextMenuOpen]);

  const closeContextMenu = () => {
    setIsContextMenuCloseAnimationRunning(true);
    setTimeout(() => {
      setIsContextMenuOpen(false);
      setIsContextMenuCloseAnimationRunning(false);
      window.removeEventListener('click', closeContextMenu);
      window.removeEventListener('scroll', closeContextMenu);
      setContextMenuPosition({ top: 0, left: 0 });
      setContextMenuCard(null);
    }, CONTEXT_MENU_ANIMATION_DURATION);
  };

  const setPosition = (anchor: HTMLButtonElement) => {
    const { bottom, left, right } = anchor.getBoundingClientRect();

    if (right + CONTEXT_MENU_SAFE_MARGIN > window.innerWidth) {
      setContextMenuPosition({
        top: bottom,
        left: right - CONTEXT_MENU_WIDTH
      });
    } else {
      setContextMenuPosition({
        top: bottom,
        left: left
      });
    }
  };

  const openContextMenu = (anchor: HTMLButtonElement, newCard: Card) => {
    if (contextMenuCard === newCard) {
      closeContextMenu();
      return;
    }

    if (isContextMenuOpen) {
      closeContextMenu();
      setTimeout(() => {
        setContextMenuCard(newCard);
        setCard(newCard);
        setIsContextMenuOpen(true);
        setPosition(anchor);
      }, CONTEXT_MENU_REOPEN_ANIMATION_DURATION);
      return;
    }

    setPosition(anchor);

    setCard(newCard);
    setContextMenuCard(newCard);
    setIsContextMenuOpen(true);
  };

  const closeAll = () => {
    setIsOverlayCloseAnimationRunning(true);
    setTimeout(() => {
      setIsOverlayOpen(false);
      setIsOverlayCloseAnimationRunning(false);
      setIsRenameDialogOpen(false);
      setIsDeleteDialogOpen(false);
      setIsContextMenuOpen(false);
    }, DEFAULT_ANIMATION_DURATION);
  };

  return (
    <DesktopControlsContext.Provider
      value={{
        card,
        isRenameDialogOpen,
        isDeleteDialogOpen,
        isContextMenuOpen,
        isOverlayOpen,
        isRenameDialogCloseAnimationRunning,
        isDeleteDialogCloseAnimationRunning,
        isContextMenuCloseAnimationRunning,
        isOverlayCloseAnimationRunning,
        contextMenuPosition,
        openRenameDialog,
        openDeleteDialog,
        openContextMenu,
        closeRenameDialog,
        closeDeleteDialog,
        closeAll
      }}
    >
      {children}
    </DesktopControlsContext.Provider>
  );
};
