import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/CardContext';
import { useDesktopControlsContext } from '../../../context/DesktopControlsContext';
import RemoveDialog from '../../Shared/Controls/RemoveDialog';
import ContextMenu from './ContextMenu';
import RenameDialog from './RenameDialog';
import { Overlay } from './styled';

const Controls = () => {
  const { t } = useTranslation();
  const {
    isOverlayOpen,
    isOverlayCloseAnimationRunning,
    closeAll,
    isContextMenuOpen,
    isContextMenuCloseAnimationRunning,
    contextMenuPosition,
    card,
    isDeleteDialogOpen,
    isDeleteDialogCloseAnimationRunning,
    isRenameDialogOpen,
    isRenameDialogCloseAnimationRunning
  } = useDesktopControlsContext();

  const { deleteCard } = useCardContext();

  const handleDelete = () => {
    if (card) {
      deleteCard(card.id, closeAll);
    }
  };
  return (
    <>
      {isOverlayOpen && <Overlay isClose={isOverlayCloseAnimationRunning} onClick={closeAll} />}

      {isContextMenuOpen && (
        <ContextMenu
          isClose={isContextMenuCloseAnimationRunning}
          position={contextMenuPosition}
          card={card}
        />
      )}

      {isDeleteDialogOpen && (
        <RemoveDialog
          isClose={isDeleteDialogCloseAnimationRunning}
          text={t('are_you_sure')}
          action={handleDelete}
          close={closeAll}
        />
      )}

      {isRenameDialogOpen && (
        <RenameDialog isClose={isRenameDialogCloseAnimationRunning} close={closeAll} />
      )}
    </>
  );
};

export default Controls;
