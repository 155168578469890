import styled from 'styled-components';
import { Container } from '../styled';

export const NoCardContainer = styled(Container)`
  gap: 15px;
  padding: 15px;
  box-sizing: border-box;
`;

export const NoCardsImage = styled.img`
  width: 100px;
  margin-bottom: 15px;
`;
