import styled, { css } from 'styled-components';

export const DialogContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  width: 345px;
  background-color: #fff;
  border-radius: 7px;

  ${(props: { isClose: boolean }) =>
    props.isClose
      ? css`
          animation: hide 0.3s linear;
        `
      : css`
          animation: show 0.3s linear;
        `}

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DialogText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #212c3a;
  padding: 20px;
  text-align: center;
  overflow: hidden;
`;

export const DialogButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
`;

export const DialogButton = styled.button`
  width: 50%;
  height: 100%;
  background-color: #fff;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #212c3a;
  border-top: 1px solid #eaebf2;
  flex: 1;
`;

export const DialogCancelButton = styled(DialogButton)`
  border-right: 1px solid #eaebf2;
  color: #4c88f9;
  border-end-start-radius: 7px;
`;

export const DialogRemoveButton = styled(DialogButton)`
  color: #ff586d;
  border-end-end-radius: 7px;
`;
