import React, { FC } from 'react';
import DesktopCardList from './components/Desktop/CardList';
import DesktopControls from './components/Desktop/Controls';
import MobileCardsList from './components/Mobile/CardsList';
import MobileControls from './components/Mobile/Controls';
import { CardProvider } from './context/CardContext';
import { DesktopControlsProvider } from './context/DesktopControlsContext';
import { MobileControlsProvider } from './context/MobileControlsContext';
import { NotificationProvider } from './context/NotificationContext';
import { AppProps, BooleanString } from './globalTypes';
import az from './locales/az.json';
import ru from './locales/ru.json';

const UserCardsApp: FC<AppProps> = ({ dataset }) => {
  return (
    <>
      {dataset.isMobileSite === BooleanString.TRUE ? (
        <MobileControlsProvider>
          <NotificationProvider>
            <CardProvider>
              <MobileCardsList />
              <MobileControls />
            </CardProvider>
          </NotificationProvider>
        </MobileControlsProvider>
      ) : (
        <DesktopControlsProvider>
          <NotificationProvider isDesktop>
            <CardProvider>
              <DesktopCardList />
              <DesktopControls />
            </CardProvider>
          </NotificationProvider>
        </DesktopControlsProvider>
      )}
    </>
  );
};

export default { App: UserCardsApp, az, ru };
