import styled, { css } from 'styled-components';

export const RenameDialogContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  width: 455px;
  background-color: #fff;
  border-radius: 7px;

  ${(props: { isClose: boolean }) =>
    props.isClose
      ? css`
          animation: hide 0.3s linear;
        `
      : css`
          animation: show 0.3s linear;
        `}

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const RenameDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 30px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #212c3a;
`;

export const CloseButton = styled.button`
  cursor: pointer;
`;
