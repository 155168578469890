import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/CardContext';
import { useMobileControlsContext } from '../../../context/MobileControlsContext';
import { Card } from '../../../globalTypes';
import RemoveDialog from '../../Shared/Controls/RemoveDialog';
import EditCard from '../../Shared/EditCard';
import MenuList from './MenuList';
import MobileMenu from './MobileMenu';
import { MobileOverlay } from './styled';

const Controls = () => {
  const {
    closeAll,
    isOverlayOpen,
    isOverlayCloseAnimationRunning,
    isMenuCloseAnimationRunning,
    isMenuOpen,
    isRenameMenuOpen,
    isRenameMenuCloseAnimationRunning,
    isDialogOpen,
    isDialogCloseAnimationRunning,
    card
  } = useMobileControlsContext();

  const { t } = useTranslation();

  const { deleteCard } = useCardContext();

  const handleDelete = () => {
    if (card) {
      deleteCard(card.id, closeAll);
    }
  };

  return (
    <>
      {isOverlayOpen && (
        <MobileOverlay isClose={isOverlayCloseAnimationRunning} onClick={closeAll} />
      )}

      {isMenuOpen && (
        <MobileMenu
          title={card?.name as string}
          isCloseAnimationRunning={isMenuCloseAnimationRunning}
        >
          <MenuList />
        </MobileMenu>
      )}

      {isRenameMenuOpen && (
        <MobileMenu
          title={t('rename_card')}
          isCloseAnimationRunning={isRenameMenuCloseAnimationRunning}
        >
          <EditCard card={card as Card} close={closeAll} />
        </MobileMenu>
      )}

      {isDialogOpen && (
        <RemoveDialog
          text={t('are_you_sure')}
          action={handleDelete}
          isClose={isDialogCloseAnimationRunning}
          close={closeAll}
        />
      )}
    </>
  );
};

export default Controls;
