import styled, { css } from 'styled-components';

export const EditCardContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
  width: 100%;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      padding: 25px 30px 30px 30px !important;
    `}
`;

export const EditLabel = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: #8d94ad;
`;

export const EditInput = styled.input<{ isError: boolean }>`
  font-size: 15px !important;
  line-height: 18px !important;
  color: #212c3a !important;
  font-weight: 400 !important;
  border-bottom: 1px solid #eaebf2 !important;
  padding: 10px 0 !important;
  border-radius: 0 !important;

  ${({ isError }) =>
    isError &&
    css`
      border-color: #ff586d !important;
    `}
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ClearButton = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const ErrorMessage = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #ff586d;
`;
