import styled, { css } from 'styled-components';
import { Position } from '../../../../globalTypes';

export const ContextMenuContainer = styled.ul<{ position: Position; isClose: boolean }>`
  position: fixed;
  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;

  background-color: #fff;
  border: 1px solid #eaebf2;
  box-shadow: 0px 10px 20px 0px #212c3a0d;
  border-radius: 14px;
  padding: 10px 0;
  z-index: 1000;

  ${({ isClose }) =>
    isClose
      ? css`
          animation: contextHide 0.1s linear;
        `
      : css`
          animation: contextShow 0.1s linear;
        `}

  @keyframes contextShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes contextHide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  display: flex;
  flex-direction: column;

  li:last-child button {
    border-bottom: none;
    color: #ff586d;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  -webkit-tap-highlight-color: transparent;

  font-size: 15px;
  line-height: 18px;
  color: #212c3a;
  font-weight: 400;

  &:hover {
    background-color: #f6f7fa;
  }
`;
