import React from 'react';
import { useCardContext } from '../../../../context/CardContext';
import { useMobileControlsContext } from '../../../../context/MobileControlsContext';
import { MenuItemType } from '../../../../globalTypes';
import deleteIcon from '../../../../images/delete.svg';
import renameIcon from '../../../../images/edit.svg';
import starIcon from '../../../../images/star.svg';
import MenuItem from './MenuItem';
import { List } from './styled';

const MenuList = () => {
  const ref = React.useRef<HTMLUListElement>(null);
  const { openDialog, openRenameMenu, card, closeAll } = useMobileControlsContext();
  const { setPrimaryCard } = useCardContext();

  const menus: MenuItemType[] = [
    {
      id: 1,
      text: 'make_default',
      action: () => setPrimaryCard(card?.id as number, closeAll),
      icon: starIcon
    },
    {
      id: 2,
      text: 'rename_card',
      action: openRenameMenu,
      icon: renameIcon
    },
    {
      id: 3,
      text: 'delete_card',
      action: openDialog,
      icon: deleteIcon
    }
  ];
  return (
    <List ref={ref}>
      {menus.slice(card?.primary ? 1 : 0).map(menu => (
        <MenuItem key={menu.id} menu={menu} />
      ))}
    </List>
  );
};

export default MenuList;
