import { useState, useEffect } from 'react';

export const usePaymentModal = () => {
  const [userCards, setUserCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [choseCardModalVisible, setChoseCardModalVisible] = useState(false);

  const openChoseCardModal = () => {
    setChoseCardModalVisible(true);
  };

  const closeChoseCardModal = () => {
    setChoseCardModalVisible(false);
  };

  const fetchUserCards = async () => {
    const userCardsData = await fetch('/api/v2/user_cards').then(res => res.json());

    setUserCards(userCardsData);
  };

  useEffect(() => {
    fetchUserCards();
  }, []);

  useEffect(() => {
    if (userCards.length) {
      setSelectedCard(userCards.find(card => card.primary));
    }
  }, [userCards]);

  return {
    userCards,
    selectedCard,
    setSelectedCard,
    choseCardModalVisible,
    openChoseCardModal,
    closeChoseCardModal
  };
};
