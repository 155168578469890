import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import type { Notification as NotificationType } from '../../../globalTypes';
import { NotificationType as NotificationMessageType } from '../../../globalTypes';
import failure from '../../../images/failure.svg';
import success from '../../../images/success.svg';
import warning from '../../../images/warning.svg';
import { NotificationContainer } from './styled';

interface NotificationProps {
  notification: NotificationType;
  isClose: boolean;
  isDesktop?: boolean;
}

const Notification: FC<NotificationProps> = ({ notification, isClose, isDesktop = false }) => {
  const getIconSrc = (type: NotificationMessageType) => {
    switch (type) {
      case NotificationMessageType.SUCCESS:
        return success;
      case NotificationMessageType.ERROR:
        return failure;
      case NotificationMessageType.INFO:
        return warning;
      default:
        return success;
    }
  };
  return (
    <NotificationContainer isClose={isClose} isDesktop={isDesktop}>
      <img src={getIconSrc(notification.type)} alt="Success" />
      <Trans i18nKey={notification.message.key} values={notification.message.values} />
    </NotificationContainer>
  );
};

export default Notification;
