import React, { FC } from 'react';
import { useCardContext } from '../../../context/CardContext';
import AddNewCard from '../../Shared/AddNewCard';
import EmptyList from '../../Shared/EmptyList';
import { Spinner } from '../../Shared/Spinner';
import CardItem from './CardItem';
import { CardListContainer } from './styled';

const CardsList: FC = () => {
  const { cards, isLoading } = useCardContext();

  if (isLoading) {
    return (
      <CardListContainer>
        <Spinner />
      </CardListContainer>
    );
  }

  if (!cards.length) {
    return <EmptyList />;
  }

  return (
    <CardListContainer>
      {cards.map(card => (
        <CardItem key={card.id} card={card} />
      ))}

      {cards.length < 6 && <AddNewCard />}
    </CardListContainer>
  );
};

export default CardsList;
