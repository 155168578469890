import React from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

export const ChoseCardModal = ({
  closeChoseCardModal,
  userCards,
  selectedCard,
  setSelectedCard,
  isMobileSite
}) => {
  const { t } = useTranslation();
  return (
    <div className={cls('chose-card-modal', { 'chose-card-modal--mobile': isMobileSite })}>
      <div
        className={cls('chose-card-modal__header chouse-header', {
          'chose-card-modal__header--mobile': isMobileSite
        })}
      >
        <span
          className={cls('chose-header__title', { 'chose-header__title--mobile': isMobileSite })}
        >
          {t('my-cards')}
        </span>
        <button className="chose-header__close" type="button" onClick={closeChoseCardModal} />
      </div>
      <div
        className={cls('chose-card-modal__cards', 'chose-cards', {
          'chose-card-modal__cards--mobile': isMobileSite
        })}
      >
        {userCards.map(card => (
          <button
            className={cls('chose-cards__button', {
              'chose-cards__button--active': card.id === selectedCard.id,
              'chose-cards__button--mobile': isMobileSite
            })}
            type="button"
            key={card.id}
            onClick={() => {
              setSelectedCard(card);
              closeChoseCardModal();
            }}
          >
            <span className="card-title">{card.name}</span>
            <div className={cls('card-footer', { 'card-footer--mobile': isMobileSite })}>
              <span className={`option option__${card.system.toLowerCase()}`} />
              {card.id === selectedCard.id && !isMobileSite && (
                <span className="card-footer__check" />
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
